import Component from '../core/Component';
import Choices from 'choices.js'

export default class Select extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            select: null
         }
    }

    prepare() {

        const choices = new Choices(this.ref.select, {
            searchEnabled: false,
            itemSelectText: '',
            shouldSort: false,
            placeholder: true
        });

        if (this.element.hasAttribute("data-redirect")) {
            this.element.addEventListener("change",:: this.handleChange)
        }
    }

    handleChange() {
        const url = this.ref.select.options[0].value
        window.location.href = url
    }
}
